<template>
  <div>  
    <!-- Errors -->
    <div v-if="isError === true">
      <div v-for="error in errors" :key="error">
        <div v-for="el in error" :key="el">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-text color="danger">{{el}}</ion-text>
          </ion-item>
        </div>
      </div>
    </div>
    <div class="padding-left-md padding-right-md">
      <h4>Who Are You?</h4>
      <ion-text>The one-liner</ion-text>
    </div>
    <div class="padding-left-md padding-right-md">
      <ion-item class="ion-text-center">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-textarea v-model="shortDescription" placeholder="Type here"></ion-textarea>
      </ion-item>
    </div>
    <div v-if="shortDescription">
      <ion-item lines="none" v-if="shortDescription.length > 72">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-text color="danger">{{shortDescription.length}} characters</ion-text>
      </ion-item>
      <ion-item lines="none" v-if="shortDescription.length <= 72">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-text>{{shortDescription.length}} characters</ion-text>
      </ion-item>
    </div>
    <div class="ion-padding-top ion-text-center">
      <ion-button v-if="shortDescription != null && shortDescription.length <= 72" @click.prevent="storeShortDescription()">Save</ion-button>
    </div>
  </div>
</template>

<script>
import { IonText, IonTextarea, IonItem, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileAbout', 
  props: ['store'],
  components: {
    IonText, IonTextarea, IonItem, IonButton
  },
  setup(props) {
    const isError = ref(false);
    const errors = ref([]);
    const shortDescription = ref(null)
    const timeout = { default: 1000 }
    const toastMessage = ref(null)

    onMounted(() => {
      if(props.store.authUser.profile.short_description != null) {
        shortDescription.value = props.store.authUser.profile.short_description;
      }
    })

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 6000,
          message: toastMessage.value,
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    function storeShortDescription() {
      presentLoading()
      apiClient.post('/api/post/user-short-description', 
        {
          short_description: shortDescription.value           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            localStorage.setItem('authUser', JSON.stringify(response.data.user));
            this.dataUp(response.data.user)
            toastMessage.value = 'You have updated your profile.'
            isError.value = false
            errors.value = []
            openToast()

          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'There was a problem.'
            openToast()
            isError.value = true
            errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
          console.log(response)

   
        }).catch(error => {
          console.log(error)  
          // credentials didn't match
          isError.value = true
          errors.value = error.response.data.errors
      });        
     
    }
    return {
      storeShortDescription, shortDescription, presentLoading, isError, errors
    }
  },
  methods: {
    dataUp: function(user) {
      var componentData = {
        dataType: 'user',
        data: user
      }

      this.$emit('component-data', componentData)
    }
  }
});
</script>